import React from "react"
import Section from "../assets/icons/section.svg"
import SectionMobile from "../assets/icons/section-mobile.svg"
import { graphql, useStaticQuery } from "gatsby"

const VideoContent = ({ children, className, id }) => {
  const { videosUrl } = useStaticQuery(graphql`
    {
      videosUrl: allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(videosUrl)/.*.md$/" } }
      ) {
        edges {
          node {
            frontmatter {
              url
            }
          }
        }
      }
    }
  `)

  return (
    <div
      className={[
        "bg-center font-fontspring bg-no-repeat bg-cover aspect-section md:aspect-auto md:h-[936px] relative",
        className,
      ].join(" ")}
    >
      <iframe
        className="absolute top-0 left-0 w-full h-full"
        src={videosUrl.edges[0].node.frontmatter.url}
      />
      <span className="invisible absolute top-[-88px] block" id={id}>
        {id}
      </span>
      <Section className="absolute hidden w-full md:p-8 md:inline-block" />
      <SectionMobile className="absolute w-full p-6 md:hidden" />
      {children}
    </div>
  )
}

export default VideoContent
