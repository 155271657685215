import React from "react"
import ImageContent from "./imageContent"

const CampInfo = () => {
  return (
    <>
      <ImageContent className="flex items-start" image="assets/5.webp">
        <div className="z-10 mt-20 text-4xl uppercase md:text-6xl md:mx-20 mx-14">
          <p className="section-heading">Carving Camp</p>
          <h2 className="mt-8 text-azur font-fontspring-bold text-shadow-dark">
            The camp only for hardbooters. Come to Scuol, Switzerland and
            improve your carving skill!
          </h2>
        </div>
      </ImageContent>
      <div className="flex flex-col items-center px-20 py-20">
        <div className="max-w-3xl text-center text-azur">
          <p className="text-2xl">
            The camp for hardbooters only: Ride with World Cup professionals,
            learn step by step how to carve and improve your overall alpine
            snowboard skills! This camp offers specific coaching for alpine
            snowboard enthusiasts in an attractive setting. No special level is
            required. As long as you have some hardboot experience, you are
            welcome!
          </p>
          <a href="carvingcamp" className="inline-flex mt-20 btn btn-primary">
            About the camp
          </a>
        </div>
      </div>
    </>
  )
}

export default CampInfo
