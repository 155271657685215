import React from "react"
import Content from "./content"
import GoldMedalist from "../assets/icons/gold-medalist.svg"
import SilverMedalist from "../assets/icons/silver-medalist.svg"
import BronzeMedalist from "../assets/icons/bronze-medalist.svg"
import Worldcup from "../assets/icons/worldcup.svg"
import Champion from "../assets/icons/champion.svg"

const Success = props => {
  return (
    <div>
      <span className="text-white">{props.title}</span>
      <div className="mt-2 text-white text-lg flex space-x-4 max-w-[200px]">
        {props.children}
      </div>
    </div>
  )
}

const Successes = () => {
  return (
    <Content>
      <p className="section-heading">Successes</p>
      <div className="flex space-x-24 overflow-x-auto lg:justify-center mt-14">
        <Success title="2018">
          <GoldMedalist />
          <p>Gold Medalist Olympic Games</p>
        </Success>
        <Success title="2014">
          <SilverMedalist />
          <p>Silver Medalist Olympic Games</p>
        </Success>
        <Success title="2018">
          <Worldcup />
          <p>Overall Worldcup Winner</p>
        </Success>
        <Success title="2017">
          <BronzeMedalist />
          <p>Bronze Medalist World Championships</p>
        </Success>
        <Success title="7x">
          <Champion />
          <p>Swiss Champion</p>
        </Success>
      </div>
    </Content>
  )
}

export default Successes
