import React from "react"
import Header from "../components/header"
import ImageContent from "../components/imageContent"
import VideoContent from "../components/videoContent"
import Content from "../components/content"
import Biography from "../components/biography"
import Successes from "../components/successes"
import SocialMedia from "../components/socialMedia"
import Speeches from "../components/speeches"
import CampInfo from "../components/campInfo"
import Sponsors from "../components/sponsors"
import Footer from "../components/footer"

export default function Home() {
  const videoSlot = () => (
    <div className="z-10 text-4xl uppercase md:text-6xl mt-14 md:mt-20 mx-14 md:mx-20">
      <p className="section-heading">Videos</p>
      <h2 className="mt-8 text-white font-fontspring-bold text-shadow-light">
        Enjoy my collection of videos that have been created over the years.
      </h2>
      <a
        href="/videos"
        className="inline-flex mt-8 md:mt-16 btn btn-secondary text-shadow-light"
      >
        Show videos
      </a>
    </div>
  )

  return (
    <>
      <Header />
      <ImageContent className="flex md:items-center bg-first-image-mobile md:bg-first-image">
        <div className="z-10 text-white uppercase text-7xl mx-14 md:mx-20 mt-14 md:mt-0">
          <h1 className="font-fontspring-bold text-shadow-light">
            Nevin Galmarini
          </h1>
          <p className="text-shadow-light">Olympic Gold Medalist</p>
        </div>
      </ImageContent>
      <Content>
        <div className="py-12">
          <h3 className="section-heading">Testimonial</h3>
          <p className="mt-4 text-6xl text-white uppercase font-fontspring-bold max-w-[900px] tracking-wide">
            «It was, even if it's easy to say in retrospect, a victory with a promise. Because Galmarini did everything possible to achieve it. He is virtually a prototype for how success can be planned to a certain extent.»
          </p>
          <p className="mt-10 text-xl text-orange">Aargauer Zeitung</p>
        </div>
      </Content>
      <Biography />
      <Successes />
      <SocialMedia />
      <Speeches />
      <ImageContent
        className="flex items-start md:hidden"
        image="assets/4.webp"
      >
        {videoSlot()}
      </ImageContent>
      <VideoContent className="items-start hidden md:flex">
        {videoSlot()}
      </VideoContent>

      <CampInfo />
      <Sponsors />
      <Footer />
    </>
  )
}
