import React from "react"
import Instagram from "../assets/icons/instagram.svg"
import Linkedin from "../assets/icons/linkedin.svg"
import Facebook from "../assets/icons/facebook.svg"

const SocialMediaLink = props => (
  <a
    href={props.link}
    className="md:w-[420px] md:h-[420px] w-[400px] h-[340px] overflow-hidden relative cursor-pointer mb-8"
    target="_blank"
  >
    <div
      className="w-full h-full transition-all bg-cover hover:scale-125"
      style={{ backgroundImage: `url('${props.image}')` }}
    />
    <div className="text-2xl text-white uppercase font-fontspring-bold absolute top-[16px] left-[16px] pointer-events-none">
      {props.children}
    </div>
  </a>
)

const SocialMedia = () => {
  return (
    <div className="flex flex-wrap justify-center px-8 py-20 lg:space-x-10">
      <SocialMediaLink
        link="https://www.instagram.com/nevingalmarini/"
        image="assets/instagram.webp"
      >
        <Instagram className="mb-4" />
        Instagram
      </SocialMediaLink>
      <SocialMediaLink
        link="https://www.linkedin.com/in/nevin-galmarini-b4396397?originalSubdomain=ch"
        image="assets/linkedin.webp"
      >
        <Linkedin className="mb-4" />
        Linkedin
      </SocialMediaLink>
      <SocialMediaLink
        link="https://www.facebook.com/nevingalmarini/"
        image="assets/facebook.webp"
      >
        <Facebook className="mb-4" />
        Facebook
      </SocialMediaLink>
    </div>
  )
}

export default SocialMedia
