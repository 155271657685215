import React from "react"
import Content from "./content"
import ImageContent from "./imageContent"

const Speeches = () => {
  return (
    <>
      <ImageContent
        className="flex items-start"
        image="assets/3.webp"
        id="speeches"
      >
        <div className="z-10 mt-16 text-4xl uppercase md:text-6xl mx-14 md:mx-20 md:mt-36">
          <p className="section-heading">Speeches</p>
          <h2 className="mt-8 text-white font-fontspring-bold text-shadow-light">
            For four years, I invested and risked everything to achieve a single
            goal: To turn Olympic silver into Olympic gold.
          </h2>
        </div>
      </ImageContent>
      <Content>
        <h3 className="section-heading">Speeches</h3>
        <div className="flex flex-col-reverse justify-between my-8 md:flex-row">
          <div className="flex flex-col justify-between md:w-[60%] max-w-3xl md:mr-32">
            <div className="w-full text-center">
              <p className="mt-10 text-2xl text-white">
                Would you like to gain insight into the athlete's mindset or
                even incorporate some of it into your business or team? I share
                my experiences of how I perfected my system with honest work and
                a tremendous team. I show why mindset plays such an important
                role and why sometimes you are still in your comfort zone even
                though it feels like hard work.
              </p>
              <p className="mt-10 text-2xl text-white">
                In an interactive keynote with emotional videos I let you dive
                into my mindset, which I internalized over 15 years as a
                professional snowboarder. Let me know your needs and I will
                customize my speech to your requirements.
              </p>
            </div>
            <div className="flex justify-center mt-8">
              <a
                href="mailto:info@nevingalmarini.ch"
                className="btn btn-secondary"
              >
                Contact me
              </a>
            </div>
          </div>
          <div className="md:w-[40%]">
            <img src="assets/speeches.webp" />
          </div>
        </div>
      </Content>
    </>
  )
}

export default Speeches
