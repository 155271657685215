import React from "react"
import { useState } from "react"
import ImageContent from "../components/imageContent"

const Biography = () => {
  const [isExpanded, setIsExpanded] = useState(false)

  return (
    <>
      <ImageContent
        id="biography"
        className="flex md:items-end"
        image="assets/2.webp"
      >
        <div className="z-10 mb-20 text-4xl uppercase md:text-6xl mx-14 md:mx-20 mt-16 md:mt-0">
          <p className="section-heading">Biography</p>
          <h2 className="mt-8 font-fontspring-bold text-azur text-shadow-dark">
            About the dream to be the best snowboarder in the world.
          </h2>
          <button
            className="mt-16 btn btn-primary"
            style={{ visibility: isExpanded ? "hidden" : "visible" }}
            onClick={() => setIsExpanded(true)}
          >
            Read more
          </button>
        </div>
      </ImageContent>
      {isExpanded && (
        <div className="flex flex-col items-center px-20 py-20">
          <div className="max-w-3xl text-center text-azur">
            <p className="text-2xl">
              In the spring of 2022, the time had come: After more than 15
              wonderful years as a professional snowboarder, I ended my career
              with one last World Cup start in Berchtesgaden. I look back on an
              incredibly wonderful time during which all my sporting goals and
              dreams came true. I made the leap into the World Cup, developed
              into one of the best riders in the world, won the Olympic silver
              medal in Sochi 2014 and finally crowned my career in 2018 when I
              won Olympic gold in Pyeongchang and the Overall Parallel Snowboard
              World Cup globe in the same winter.
            </p>
            <p className="mt-12 text-lg">
            Olympic gold, Olympic silver and Overall World Cup winner: these are the greatest successes of my 16-year snowboarding career. 
I spent the first 13 years of my life in Herisau AR. Then we moved to Ardez in the Lower Engadine. And this is where my heart still beats today, this is where I belong.
Actually I wanted to become a skier, also because my mother won nine (!) medals at the Olympic Games for the deaf. But my path led me to snowboarding. Right after the sports high school at the Hochalpines Institut in Ftan, I put everything on professional sports and stood on a World Cup podium for the first time in Arosa in 2011.
But my ultimate year was 2018: Olympic champion, Graubünden Sportsman of the Year, graduation with a Bachelor of Science in Business Administration and, more importantly, my wife Nadja and I became parents to our twins Eddie and Louie.
After four Olympic participations, the time had come on March 20, 2022: I ended my long and successful career. I was very lucky to be able to achieve and experience my dreams.
Today I have a Master of Science degree in Innovation Management and I work for Berner Kantonalbank as a Relationship Manager in Asset Management. In addition, I am the coordinator of top sports and studies at the Fernfachhochschule Schweiz.
However, snowboarding remains my passion, as a TV expert for the Swiss TV station SRF for all alpine snowboard broadcasts, as an OC member of the snowboard World Cup Scuol, as the organizer of the Snowboard Carving Camp or simply as a snowboarder myself, when I draw my lines in the powder.
            </p>
            <button
              className="mt-20 btn btn-primary"
              onClick={() => {
                setIsExpanded(false)
                document
                  .getElementById("biography")
                  .scrollIntoView({ behavior: "smooth" })
              }}
            >
              Read less
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default Biography
